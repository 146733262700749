import React from "react";

function Footer() {
  return (
    <div>
      <footer className="main-footer">
        <strong>
          Copyright © 2024{" "}
          <a href="https://montage-video.yopyo.com/">montage-video.yopyo.com</a>{" "}
          .
        </strong>{" "}
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 1.0.1 beta
        </div>
      </footer>
      {/* Control Sidebar */}
      <aside className="control-sidebar control-sidebar-dark">
        {/* Control sidebar content goes here */}
      </aside>
      {/* /.control-sidebar */}
    </div>
  );
}

export default Footer;
