import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";

import MainHeader from "./components/MainHeader";
import Home from "./components/Home";
import Footer from "./components/Footer";
import SideNav from "./components/SideNav";
import VideoEditor from "./pages/VideoEditor";

function App() {
  return (
    <Router>
      <div className="wrapper">
        <MainHeader />
        <SideNav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/video-editor/:projectName" element={<VideoEditor />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
