import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

function SideNav() {
  
  const navigate = useNavigate();
  const location = useLocation(); // Hook pour obtenir l'emplacement actuel
  const [projects, setProjects] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);

  const API_URL = process.env.REACT_APP_API_HOST; 

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/getAllProjectAndVideoFiles.php`
        );
        if (response.data.status === "success") {
          setProjects(response.data.projects);
        } else {
          console.error("Error fetching projects:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [location.pathname]);

  useEffect(() => {
    // Extraire l'ID du projet sélectionné depuis les paramètres de la route
    const pathParts = location.pathname.split("/");
    const projectId = pathParts[pathParts.length - 1];
    setSelectedProject(projectId);
  }, [location.pathname]);

  const handleVideoEditClick = (project) => {
    localStorage.removeItem('unsavedVideos');
    localStorage.setItem("selectedProject", project);
    navigate(`/video-editor/${project}`);
  };

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a href="index3.html" className="brand-link">
          <img
            src="../dist/img/AdminLTELogo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">
            montage-video 1.0
          </span>
        </a>
        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src="../dist/img/user2-160x160.jpg"
                className="img-circle elevation-2"
                alt="John Doe"
              />
            </div>
            <div className="info">
              <a href="#" className="d-block">
                John Doe
              </a>
            </div>
          </div>
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item menu-is-opening menu-open">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-project-diagram" />
                  <p>
                    Projets
                    <i className="fas fa-angle-left right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  {loading ? (
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="nav-icon fas fa-spinner fa-spin" />
                        <p>Chargement...</p>
                      </a>
                    </li>
                  ) : (
                    Object.keys(projects).map((project, index) => (
                      <li className="nav-item" key={index}>
                        <a
                          href="#"
                          className={`nav-link ${
                            selectedProject === project ? "active" : ""
                          }`}
                          onClick={() => handleVideoEditClick(project)}
                        >
                          <i className="fas fa-film nav-icon" />
                          <p>{project}</p>
                        </a>
                      </li>
                    ))
                  )}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}

export default SideNav;
