import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import ReactPlayer from "react-player";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

function VideoEditor() {
  const { projectName } = useParams(); // Récupère le nom du projet depuis les paramètres de l'URL
  const { projectName: paramProjectName } = useParams();

  const navigate = useNavigate();

  const [videoUrl, setVideoUrl] = useState("");
  const [projects, setProjects] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoTrackList, setVideoTrackList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progressBarWidth, setProgressBarWidth] = useState("0px");
  const videosContainerRef = useRef(null);

  const [videoName, setVideoName] = useState("final_video");
  const inputRefs = useRef([]);
  const [defaultValue, setDefaultValue] = useState({
    indice: null,
    valeur: null,
  });
  const [clickedVideoName, setClickedVideoName] = useState("");
  const [clickedVideoNumero, setClickedVideoNumero] = useState(""); // État pour stocker le nom de la vidéo cliquée
  const [visibleOptions, setVisibleOptions] = useState(null);
  const [selectedVideoTrack, setSelectedVideoTrack] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoStartTime, setVideoStartTime] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [videoDuration, setVideoDuration] = useState(null);
  const [defaultVideoDuration, setDefaultVideoDuration] = useState(null);
  const [templateName, setTemplateName] = useState("");

  const [showVideoStartSliderModal, setShowVideoStartSliderModal] =
    useState(false);
  const sliderRef = useRef(null);

  const [err, setErr] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [selectedVideoDuration, setSelectedVideoDuration] = useState(null);
  const refVideo = useRef(null); // Déclaration de la référence
  const [totalDuration, setTotalDuration] = useState(0);
  const [current_time, setCurrent_time] = useState(0);
  const [mins, setMins] = useState(0);
  const [secs, setSecs] = useState("00");
  const [total_mins, setTotal_mins] = useState(0);
  const [total_secs, setTotal_secs] = useState("00.0");

  const fileInputRef = useRef(null); // Référence à l'élément input
  const [fileNames, setFileNames] = useState([]);
  const [videoLimit, setVideoLimit] = useState(0);

  const configuredDefaultVideoDurationValueIndex0 = 1.6;

  const [selectedVideos, setSelectedVideos] = useState([]);

  const API_URL = process.env.REACT_APP_API_HOST;

  // Fonction pour afficher la réponse dans la console
  const logApiResponse = (response, apiName) => {
    console.log(`Réponse de l'API ${apiName} :`, response);
  };

  // Fonction pour vérifier si le dossier existe
  const checkIfFolderExists = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/checkIfExistFolderNameAsProjectNameInVideos.php?folderName=${encodeURIComponent(
          projectName
        )}`
      );
      logApiResponse(
        response.data,
        "checkIfExistFolderNameAsProjectNameInVideos"
      );
      return response.data.status === "success";
    } catch (error) {
      console.error("Erreur lors de la vérification du dossier :", error);
      return false;
    }
  };

  // Fonction principale asynchrone pour exécuter les appels API
  const fetchData = async () => {
    setLoading(true);
    resetSelectedVideos();
    console.log("========> fetchData() <========");
    const folderExists = await checkIfFolderExists();
    if (!folderExists) {
      navigate("/"); // Redirige si le dossier n'existe pas
      setLoading(false);
      return;
    }

    try {
      // Appel de toutes les API nécessaires en parallèle
      const [videoTrackListResponse, videoResponse, videoByProjectResponse] =
        await Promise.all([
          axios.get(
            `${API_URL}/getVideosByVideoListInTxt.php?parentFolderName=${encodeURIComponent(
              projectName
            )}`
          ),
          fetch(
            `${API_URL}/getFinalVideos.php?project=${encodeURIComponent(
              projectName
            )}`
          ).then((res) => res.json()),
          // axios.get(
          //   `${API_URL}/getAllVideoFilesByFolderName.php?folderName=${encodeURIComponent(
          //     projectName
          //   )}`
          // ),
          axios.get(`${API_URL}/getAllVideosFromAllProjects.php`),
        ]);

      // Log des réponses API
      logApiResponse(videoTrackListResponse.data, "getVideosByVideoListInTxt");
      setVideoTrackList(videoTrackListResponse.data);
      logApiResponse(videoResponse, "getFinalVideos");
      logApiResponse(
        videoByProjectResponse.data,
        "getAllVideoFilesByFolderName"
      );

      // Traitement des réponses
      if (videoTrackListResponse.data.status === "success") {
        setVideos(videoTrackListResponse.data.projects);
      }

      const videoFile = videoResponse.find((file) =>
        file.name.endsWith(".mp4")
      );
      if (videoFile) {
        setVideoUrl(
          `${API_URL}/finaliser/${projectName}/${
            videoFile.name
          }?cb=${new Date().getTime()}`
        );
      } else {
        console.error("Aucune vidéo trouvée");
      }

      if (videoByProjectResponse.data.status === "success") {
        console.error(
          "ALL_VIDEOS : videoByProjectResponse = ",
          videoByProjectResponse.data.projects
        ); //! ALL_VIDEOS
        console.error(
          "VIDEO_TRACK_LIST : getVideosByVideoListInTxt = ",
          videoTrackListResponse.data.projects[projectName]
        ); //! VIDEO_TRACK_LIST
        // setProjects(videoByProjectResponse.data.videos); //! ALL_VIDEOS_FROM ALL_PROJECT
        // setProjects(videoByProjectResponse.data.projects[projectName]); //! ALL_VIDEOS_FROM ALL_PROJECT

        // TODO : Traitenent txt, verif colonne ancien nom

        setProjects(videoByProjectResponse.data.projects); //! ALL_VIDEOS_FROM ALL_PROJECT
      } else {
        console.error(
          "Erreur lors de la récupération des vidéos du projet :",
          videoByProjectResponse.data.message
        );
      }
      const responseTemplateName = await fetch(
        `${API_URL}/getTemplateName.php?parentFolderName=${projectName}`
      );
      if (responseTemplateName.ok) {
        const data = await responseTemplateName.json();
        // console.log("Template Name: ", data.templateName);
        setTemplateName(data.templateName);

        const responseTemplateData = await axios.get(
          `${API_URL}/getTemplateDataByTemplateName.php?templateName=${data.templateName}`
        );

        if (!responseTemplateData || !responseTemplateData.data) {
          console.error("API response data is missing or undefined");
          return;
        }

        const templateData = responseTemplateData.data;
        // console.log("const templateData = =", templateData);
        // console.log("templateName =", data.templateName);
        // console.error("templateData =", templateData.scenes[0].duration);

        // Afficher duree apres configuration
        if (visibleOptions === null || visibleOptions === 0) {
          setVideoDuration(
            parseFloat(templateData.scenes[0].duration).toFixed(1)
          );
        } else {
          setVideoDuration(
            parseFloat(templateData.scenes[0].duration).toFixed(1)
          );
        }
      } else {
        console.error(
          "Erreur lors de la récupération du templateName:",
          responseTemplateName.status
        );
      }
      //------------------------------------------------------------------------------------------

      let _unsavedVideos = JSON.parse(localStorage.getItem("unsavedVideos"));
      console.warn("visibleOptions = ", visibleOptions);

      async function fetchTemplateAndSetDuration(projectName) {
        try {
          const responseTemplateName = await fetch(
            `${API_URL}/getTemplateName.php?parentFolderName=${projectName}`
          );

          if (!responseTemplateName.ok) {
            console.error(
              "Erreur lors de la récupération du templateName:",
              responseTemplateName.status
            );
            return;
          }

          const data = await responseTemplateName.json();
          console.log("Template Name: ", data.templateName);
          setTemplateName(data.templateName);

          const responseTemplateData = await axios.get(
            `${API_URL}/getTemplateDataByTemplateName.php?templateName=${templateName}`
          );

          if (!responseTemplateData || !responseTemplateData.data) {
            console.error("API response data is missing or undefined");
            return;
          }

          const templateData = responseTemplateData.data;
          console.log("templateData =", templateData);
          console.log(
            "templateData.duration =",
            templateData.scenes[0].duration
          );

          setDefaultVideoDuration(
            parseFloat(
              videoTrackListResponse.data.projects[projectName][0].metadata
                .format.duration
            ).toFixed(1)
          );
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des données du template:",
            error
          );
        }
      }

      function setVideoStartTimeAndDuration(index) {
        const videoMetadata = _unsavedVideos[index]?.metadata || {};

        const startTime =
          videoMetadata.start_time !== undefined
            ? parseFloat(videoMetadata.start_time).toFixed(1)
            : 0;
        setVideoStartTime(startTime);

        const duration =
          videoMetadata.duration !== undefined
            ? parseFloat(videoMetadata.duration).toFixed(1)
            : parseFloat(
                videoTrackListResponse.data.projects[projectName][index]
                  ?.metadata?.format?.duration || "0"
              ).toFixed(1);
        setDefaultVideoDuration(duration);
      }

      function handleNoVideoSelected() {
        console.warn(`Aucune vidéo sélectionnée, visibleOptions === null`);

        if (_unsavedVideos === null || _unsavedVideos.length === 0) {
          console.warn(`Aucune modification à valider`);

          const projectMetadata =
            videoTrackListResponse?.data?.projects?.[projectName]?.[0]
              ?.metadata;

          if (projectMetadata) {
            // Utilisation de format.start_time si disponible, sinon utilisation de start_time
            const startTime =
              projectMetadata?.format?.start_time ||
              projectMetadata?.start_time;

            if (startTime !== undefined) {
              setVideoStartTime(parseFloat(startTime).toFixed(1));
            } else {
              console.warn(
                "Aucun start_time disponible, utilisation de la valeur par défaut."
              );
              setVideoStartTime(0.0); // Valeur par défaut si aucune start_time n'est trouvée
            }

            const duration =
              projectMetadata?.format?.duration || projectMetadata?.duration;

            if (duration !== undefined) {
              setDefaultVideoDuration(parseFloat(duration).toFixed(1));
            } else {
              console.warn(
                "Aucune durée disponible, utilisation de la valeur par défaut."
              );
              setDefaultVideoDuration(0.0); // Valeur par défaut si aucune durée n'est trouvée
            }
          } else {
            console.error(
              "Les métadonnées de la vidéo sont manquantes ou incorrectes."
            );
          }
        } else {
          console.warn(`Modifications à valider`);
          setVideoStartTimeAndDuration(0);
          fetchTemplateAndSetDuration(projectName);
        }

        setFirstVideoDetails();
      }

      function handleVideoSelected() {
        console.warn(`Une vidéo ${visibleOptions} sélectionnée`);

        if (_unsavedVideos === null || _unsavedVideos.length === 0) {
          console.warn(`Aucune modification à valider`);
          console.warn(
            "videoTrackListResponse.data.projects[projectName][0].metadata.format.start_time",
            videoTrackListResponse.data.projects[projectName][0].metadata.format
              .start_time
          );
          setVideoStartTime(
            videoTrackListResponse.data.projects[projectName][0].metadata.format
              .start_time
          );
          setDefaultVideoDuration(
            parseFloat(
              videoTrackListResponse.data.projects[projectName][0].metadata
                .format.duration
            ).toFixed(1)
          );
        } else {
          console.warn(`Modifications à valider`);
          setVideoStartTimeAndDuration(visibleOptions - 1);
        }

        setFirstVideoDetails();
      }

      function setFirstVideoDetails() {
        const videoNameWithExtension =
          videoTrackListResponse.data.projects[projectName][0].name;
        const videoNameWithoutExtension = videoNameWithExtension.substring(
          0,
          videoNameWithExtension.lastIndexOf(".")
        );
        console.log("clickedVideoName", videoNameWithoutExtension);
        setClickedVideoName(videoNameWithoutExtension);
        setClickedVideoNumero(1);
        setVisibleOptions(0);
      }

      // Logique principale
      if (visibleOptions === null || visibleOptions === 0) {
        handleNoVideoSelected();
      } else {
        handleVideoSelected();
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const project = localStorage.getItem("selectedProject");
    console.log("@Params : ", projectName);
    console.log("localStorage.getItem('selectedProject') = ", project);
    // TODO : Recuperer le templateName
    const fetchTemplateName = async () => {
      try {
        const response = await fetch(
          `${API_URL}/getTemplateName.php?parentFolderName=${projectName}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log("Template Name: ", data.templateName);
          setTemplateName(data.templateName);
        } else {
          console.error(
            "Erreur lors de la récupération du templateName:",
            response.status
          );
        }
      } catch (error) {
        console.error(
          "Erreur réseau lors de la récupération du templateName:",
          error
        );
      }
    };

    fetchTemplateName();

    console.warn("etoooooooooooo", templateName);
    fetchData();
  }, [projectName]);

  useEffect(() => {
    if (showVideoStartSliderModal && sliderRef.current) {
      const slider = new Slider(sliderRef.current, {
        min: -200,
        max: 200,
        range: true,
        value: [-100, 100],
        tooltip: "show",
        handle: "round",
      });

      slider.on("slide", (value) => {
        console.log("Slider value:", value);
        // Mettez à jour l'état si vous avez besoin de l'utiliser ailleurs
      });

      return () => {
        slider.destroy(); // Nettoyage lors du démontage du composant ou de la fermeture du modal
      };
    }
  }, [showVideoStartSliderModal]);

  useLayoutEffect(() => {
    const calculateWidth = () => {
      if (videosContainerRef.current) {
        const videoElements =
          videosContainerRef.current.querySelectorAll(".video-container");
        const totalWidth = Array.from(videoElements).reduce(
          (acc, el) => acc + el.offsetWidth,
          0
        );
        const AudioTrackWidth = totalWidth - 15; // ! Pour adapter la piste audio a la piste video
        setProgressBarWidth(`${AudioTrackWidth}px`);
      }
    };
    calculateWidth();
    window.addEventListener("resize", calculateWidth);
    return () => window.removeEventListener("resize", calculateWidth);
  }, [projects]);

  const handleAddingVideo = async (projectName, videoName) => {
    const unsavedVideos = localStorage.getItem("unsavedVideos");
    try {
      const parsedUnsavedVideos = JSON.parse(unsavedVideos);
      console.warn("parsedUnsavedVideos =", parsedUnsavedVideos);
      if (
        !parsedUnsavedVideos ||
        parsedUnsavedVideos.length === 0 ||
        parsedUnsavedVideos === null
      ) {
        console.log("Ajout de vidéo(s) possible");
        const nbVideoAGenerer =
          videoTrackList["projects"][projectName]?.length ||
          Object.keys(videoTrackList["projects"]).length;

        const _nbExistingVideos = parseInt(nbVideoAGenerer, 10);

        const _sum = parseInt(_nbExistingVideos + 1, 10);
        console.log("_nbExistingVideos + 1 = ", _sum);
        let n_maxVideoTemplate;
        try {
          console.warn(
            `${API_URL}/getLimitationTemplate.php?templateName=${templateName}`
          );
          const getLimitationResponse = await axios.get(
            `${API_URL}/getLimitationTemplate.php?templateName=${templateName}`
          );
          const data = getLimitationResponse.data;
          if (data && data.maxComment) {
            n_maxVideoTemplate = data.maxComment;
          } else {
            console.error("La réponse de l'API est invalide ou manquante.");
          }
        } catch (error) {}
        console.log("n_maxVideoTemplate = ", n_maxVideoTemplate);
        if (_sum <= n_maxVideoTemplate) {
          console.warn(`OK, go for processing handleAddingVideo`);
          setLoading(true);
          try {
            console.log("Nom de la video ajoutée = ", videoName);
            console.log("Nom du projet de la video ajoutée === ", projectName);
            console.log("Nom du projet actuel === ", paramProjectName);

            let videoNameTXT = videoName;
            if (projectName !== paramProjectName) {
              console.log(
                `Copier
                la video ${videoName} et la miniature de ${projectName}
                dans ${paramProjectName}`
              );

              // Appel à l'API pour copier la vidéo et la miniature
              const copyResponse = await fetch(
                `${API_URL}/copyVideoFileAndThumbnailFromOtherProject.php`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    originProjectName: projectName,
                    destinationProjectName: paramProjectName,
                    videoFilename: videoName,
                  }),
                }
              );

              if (!copyResponse.ok) {
                throw new Error(
                  "Échec de la copie de la vidéo et de la miniature."
                );
              }

              const copyData = await copyResponse.json();
              if (copyData.status !== 1) {
                throw new Error(
                  copyData.message ||
                    "Erreur lors de la copie de la vidéo et de la miniature."
                );
              }

              console.log(copyData.message); // Message de succès
              videoNameTXT = copyData.newFileName;
            }

            // Ajouter la vidéo dans le fichier texte du projet actuel
            // TODO : Here goes condition nom du fichier a ajouter dans TXT
            const response = await fetch(
              `${API_URL}/addVideoInTxtFileByVideoFileName.php`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  sourceFolderName: projectName,
                  destinationFolderName: paramProjectName,
                  video: videoNameTXT,
                }),
              }
            );

            if (response.ok) {
              console.log("/addVideoInTxtFileByVideoFileName.php", response);
              if (response.ok) {
                try {
                  const _response = await axios.post(
                    `${API_URL}/generate.php`,
                    {
                      parentFolderName: paramProjectName,
                      templateName: templateName,
                    }
                  );
                  console.log(">>>> _response.data.status =", _response.status);
                  if (_response.status === 200) {
                    fetchData(); // Recharge les données sans recharger la page
                  } else {
                    alert(_response.data.message);
                  }
                } catch (error) {
                  console.error("Generate error:", error);
                  alert("Erreur lors de la génération. Veuillez réessayer.");
                }
              } else {
                console.error("Erreur lors de l'ajout du fichier.");
              }
            } else {
              const errorData = await response.json();
              console.error("Détails de l'erreur :", errorData);
            }
          } catch (error) {
            console.error("Erreur :", error);
            alert(`Erreur : ${error.message}`);
          } finally {
            setLoading(false);
          }
        } else {
          alert(
            "Le nombre de vidéos dépasse le nombre maximal autorisé pour ce template."
          );
        }
      } else {
        console.error(
          "Veuillez d'abord valider les changements avant d'ajouter une vidéo"
        );
        alert(`Merci de valider les modifications avant d'ajouter la vidéo`);
      }
    } catch (error) {
      console.error(
        "Erreur lors de l'analyse des vidéos non sauvegardées :",
        error
      );
    }
  };

  const handleDelete = async (projectName, videoName) => {
    const unsavedVideos = localStorage.getItem("unsavedVideos");

    try {
      const parsedUnsavedVideos = JSON.parse(unsavedVideos);
      console.warn("parsedUnsavedVideos =", parsedUnsavedVideos);

      if (!parsedUnsavedVideos || parsedUnsavedVideos.length === 0) {
        console.log("Suppression d'une vidéo possible");
        setLoading(true);

        try {
          const response = await fetch(
            `${API_URL}/deleteVideoInTxtFileByVideoFileName.php`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                video: videoName,
                parentFolderName: projectName,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Erreur lors de la suppression du fichier.");
          }

          console.log("Delete line in txt response = ", response);
          console.log("Delete line in txt response.ok = ", response.ok);

          // Appel pour la régénération via l'API generate.php
          try {
            const _response = await axios.post(`${API_URL}/generate.php`, {
              parentFolderName: projectName,
              templateName: templateName,
            });

            console.log("Call API with POST:", {
              url: `${API_URL}/generate.php`,
              body: {
                parentFolderName: projectName,
                templateName: templateName,
              },
            });

            if (_response.status === 200) {
              console.log("Reload data by fetchData() after deleting...");
              fetchData(); // Recharge les données sans recharger la page
            } else {
              alert(_response.data.message);
            }
          } catch (error) {
            console.error("Erreur lors de la génération:", error);
            alert("Erreur lors de la génération. Veuillez réessayer.");
          }
        } catch (error) {
          console.error("Erreur réseau ou lors de la suppression:", error);
        } finally {
          setLoading(false);
        }
      } else {
        console.warn(
          "Vidéos non sauvegardées présentes :",
          parsedUnsavedVideos
        );
        alert(
          "Veuillez d'abord valider les changements avant de supprimer une vidéo."
        );
      }
    } catch (error) {
      console.error(
        "Erreur lors de l'analyse des vidéos non sauvegardées :",
        error
      );
    }
  };

  const handleDownload = async () => {
    var VIDEO_NAME = videoName;
    if (!VIDEO_NAME) {
      // alert("Veuillez entrer un nom pour la vidéo.");
      VIDEO_NAME = "final_video";
    }

    if (!videoUrl) {
      alert("Aucun fichier vidéo disponible pour le téléchargement.");
      return;
    }

    try {
      const downloadUrl = `${API_URL}/downloadVideo.php?project=${encodeURIComponent(
        projectName
      )}&video=${encodeURIComponent("final_video")}.mp4`;

      const response = await fetch(downloadUrl);

      if (!response.ok) {
        throw new Error("Erreur lors du téléchargement de la vidéo");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${VIDEO_NAME}.mp4`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Nettoyage de l'URL blob après le téléchargement
      window.URL.revokeObjectURL(url);
      console.log("Téléchargement réussi");
    } catch (error) {
      console.error("Erreur lors du téléchargement de la vidéo:", error);
      alert("Erreur lors du téléchargement. Veuillez réessayer.");
    }
  };

  const handleChangeOrder = async () => {
    setLoading(true);
    // const videoNames = videos[projectName].map((video) => video.name);
    // console.log("videoNames = ", videoNames);
    console.log("New videos order = ", videos[projectName]);
    console.log("Old videos order = ", videoTrackList["projects"][projectName]);
    // fetchTemplateLimit(templateName);

    let _newArrayVideo = videos[projectName];
    console.log("_newArrayVideo length", _newArrayVideo.length);
    console.log("Template limit", videoLimit);

    try {
      // TODO : post : Use unsavedVideos or _unsavedVideos
      const _unsavedVideosLocalStorage = JSON.parse(
        localStorage.getItem("unsavedVideos")
      );
      console.log(
        "verif start_time ==> _unsavedVideosLocalStorage",
        _unsavedVideosLocalStorage
      );
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/updateVideoOrder.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            projectName,
            newVideosOrder: videos[projectName],
            oldVideosOrder: videoTrackList["projects"][projectName],
            // videoNames: videoNames,
            unsavedVideos: JSON.parse(localStorage.getItem("unsavedVideos")),
          }),
        }
      );

      const responseText = await response.text();
      console.log("response", response);
      if (response.ok) {
        try {
          console.warn(`${API_URL}/generate.php`);
          const _response = await axios.post(`${API_URL}/generate.php`, {
            parentFolderName: projectName,
            unsavedVideos: _unsavedVideosLocalStorage,
            templateName: templateName,
          });

          console.log("Call API with POST:", {
            url: `${API_URL}/generate.php`,
            body: { parentFolderName: projectName, templateName: templateName },
          });

          console.log(
            "REGENERATION final_video after reordering: _response.data.status (should equal to 1) = ",
            _response.data.status
          );

          if (_response.status === 200) {
            console.log("Reload data by fetchData() after generate ...");

            // Mettre a jour le fichier templateName.txt apres modification
            const updateTemplateNameInTxtFile = await axios.post(
              `${API_URL}/updateTemplate.php`,
              {
                parentFolderName: projectName,
                folderName: projectName,
                newTemplateName: templateName,
              }
            );
            if (updateTemplateNameInTxtFile.data.status === 1) {
              await fetchData();
            } else {
              alert(updateTemplateNameInTxtFile.data.message);
            }
          } else {
            alert(_response.data.message);
          }
        } catch (error) {
          console.error("Generate error:", error);
          alert("Erreur lors de la génération. Veuillez réessayer.");
        }
      }
      if (!response.ok) {
        throw new Error(
          `Erreur lors de la mise à jour de l'ordre: ${responseText}`
        );
      }

      const result = JSON.parse(responseText);
      console.log("Ordre mis à jour avec succès:", result);
    } catch (error) {
      console.error("Erreur:", error);
    } finally {
      localStorage.removeItem("unsavedVideos"); // Supprimer les modifications non sauvegardees
      setLoading(false);
    }
  };

  const remplacerIndices = (
    videos,
    arrayKey,
    index1,
    nouvelleValeur1,
    index2,
    nouvelleValeur2
  ) => {
    // Créer une copie des vidéos pour éviter de modifier l'original
    const nouvellesVideos = { ...videos };
    // Créer une copie de l'array à l'intérieur de l'objet
    const array = [...nouvellesVideos[arrayKey]];
    // Remplacer les éléments à index1 et index2
    array[index1] = { ...nouvelleValeur2 };
    array[index2] = { ...nouvelleValeur1 };
    nouvellesVideos[arrayKey] = array;
    return nouvellesVideos;
  };

  const handleInputOrderChange = (index, event) => {
    console.log("indexvideo", index);
    const newValue = event.target.value.trim();

    // Ne rien faire si la valeur est vide ou non valide
    if (!newValue || isNaN(newValue) || parseInt(newValue, 10) <= 0) {
      return;
    }

    setDefaultValue({ indice: index, valeur: newValue }); // Mettre à jour la valeur saisie
  };

  const handleKeyDown = (index, event, videos) => {
    if (event.key === "Enter") {
      const newValue = defaultValue.valeur;

      const newIndex = parseInt(newValue, 10) - 1; // Convertir en index (0-based)

      if (newIndex >= videos[projectName].length || newIndex < 0) {
        // Si l'index est hors des limites, ne rien faire
        return;
      }

      let videoList = null;
      const _unsavedVideos = JSON.parse(localStorage.getItem("unsavedVideos"));
      console.warn("DEBUG _unsavedVideos", _unsavedVideos);
      console.log("DEBUG videos[projectName]", videos[projectName]);
      if (_unsavedVideos === null || _unsavedVideos.length === 0) {
        videoList = videos[projectName];
      } else {
        videoList = _unsavedVideos;
        console.warn("DEBUG use localStorage", videoList);
      }
      if (newIndex !== index) {
        const updatedVideos = remplacerIndices(
          videos,
          projectName,
          newIndex,
          videoList[newIndex],
          index,
          videoList[index]
        );

        setVideos(updatedVideos); // Mettre à jour l'état avec les nouvelles vidéos
        console.warn("updatedVideos = ", updatedVideos[projectName]); // Mettre à jour l'état avec les nouvelles vidéos

        // Ajouter le changement d'ordre dans le localStorage
        localStorage.setItem(
          "unsavedVideos",
          JSON.stringify(updatedVideos[projectName])
        );
        setDefaultValue({ indice: index, valeur: index + 1 });
      }
    }
  };

  const getDefaultValue = (index) => {
    if (defaultValue.indice === index) {
      return defaultValue.valeur;
    }
    return index + 1; // Ou retournez null si vous ne voulez pas de valeur par défaut
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleClick = async (index, event, videos) => {
    // Vérification de la disponibilité des données essentielles
    if (!templateName) {
      return console.error("templateName is not defined");
    }

    const projectVideos = videos?.[projectName];
    const selectedVideo = projectVideos?.[index];
    if (!projectVideos || !selectedVideo) {
      return console.error("Invalid videos, projectName, or index");
    }

    if (isNaN(index)) {
      return console.error("Index is not a valid number");
    }

    console.warn(`Video clicked: ${index + 1}`);

    // Récupération des vidéos non sauvegardées depuis le localStorage
    let _unsavedVideos =
      JSON.parse(localStorage.getItem("unsavedVideos")) || [];

    try {
      // Fetch template data
      const response = await axios.get(
        `${API_URL}/getTemplateDataByTemplateName.php?templateName=${templateName}`
      );
      const templateData = response?.data;

      if (!templateData) {
        return console.error("API response data is missing or undefined");
      }

      console.log(templateName, templateData);
      console.log(
        `Duration after setup = ${templateData.scenes?.[index]?.duration}`
      );

      if (!templateData.scenes) {
        return console.error("No 'scenes' property found in templateData");
      }

      const scene = templateData.scenes.find(
        (scene) => scene.index === index.toString()
      );
      if (!scene) {
        return console.warn(`No scene found for index ${index}`);
      }

      const videoDuration =
        selectedVideo.metadata?.format?.duration ||
        selectedVideo.metadata?.duration ||
        "undefined";
      console.log(`Actual video duration = ${videoDuration}`);

      if (_unsavedVideos.length === 0) {
        console.warn("No unsaved modifications");

        try {
          const videoFilename = selectedVideo.name;
          const apiUrl = `${API_URL}/getVideoDataInTXTByFilename.php?filename=${videoFilename}&parentFolderName=${projectName}`;
          const videoDataResponse = await axios.get(apiUrl);

          if (!videoDataResponse || !videoDataResponse.data) {
            return console.error("Error fetching video data from TXT API");
          }

          const videoData = videoDataResponse.data;
          console.log("Start_time from TXT API =>", videoData.start_time);

          setVideoStartTime(videoData.start_time);

          const defaultDuration = parseFloat(videoDuration || "0").toFixed(1);
          if (defaultDuration === "0") {
            console.error("Default video duration is undefined or 0");
          } else {
            setDefaultVideoDuration(defaultDuration);
          }

          const templateDuration = parseFloat(scene.duration || "0").toFixed(1);
          setVideoDuration(templateDuration);
        } catch (apiError) {
          console.error("Error fetching video data from TXT API:", apiError);
        }
      } else {
        console.warn("Unsaved modifications found");
        // Nouvelle fonctionnalité : appel à l'API pour récupérer les données vidéo du fichier TXT
        try {
          const videoFilename = selectedVideo.name;
          const apiUrl = `${API_URL}/getVideoDataInTXTByFilename.php?filename=${videoFilename}&parentFolderName=${projectName}`;
          const videoDataResponse = await axios.get(apiUrl);

          if (!videoDataResponse || !videoDataResponse.data) {
            return console.error("Error fetching video data from TXT API");
          }

          const videoData = videoDataResponse.data;
          console.log("Video data from TXT API:", videoData);

          const unsavedVideo = _unsavedVideos[index] || {};

          const localS = JSON.parse(localStorage.getItem("unsavedVideos"));
          const local = localS[index] || {};
          console.log("local.metadata.start_time", local.metadata.start_time);
          console.log("_unsavedVideos[index]", unsavedVideo);

          const { start_time, duration } = unsavedVideo.metadata || {};

          console.log(
            "LOCAL STORAGE",
            JSON.parse(localStorage.getItem("unsavedVideos"))
          );
          // console.log("_unsavedVideos", _unsavedVideos);
          if (start_time != null && start_time !== 0 && !isNaN(start_time)) {
            // Si start_time est différent de null, undefined, 0 et est un nombre valide
            console.log("localStorage.start_time => ", start_time);
            // Sauvegarde des données du fichier TXT dans le localStorage
            //! FIXXXXXX
            // _unsavedVideos[index] = {
            //   ..._unsavedVideos[index], // Conserve les autres propriétés existantes (name, thumbnail, etc.)
            //   metadata: {
            //     ..._unsavedVideos[index]?.metadata, // Conserve les autres métadonnées existantes
            //     format: {
            //       ..._unsavedVideos[index]?.metadata?.format, // Conserve les autres propriétés de 'format'
            //       start_time: start_time, // Met à jour start_time depuis localStorage ou fichier TXT
            //       duration: videoData.duration, // Met à jour la durée avec celle du fichier TXT
            //     },
            //   },
            // };
            _unsavedVideos[index] = {
              ..._unsavedVideos[index], // Conserve les autres propriétés existantes (name, thumbnail, etc.)
              metadata: {
                ..._unsavedVideos[index]?.metadata, // Conserve les autres métadonnées existantes (si elles existent)
                start_time: start_time, // Utilise start_time depuis localStorage
                duration: videoData.duration, // Met à jour la durée avec celle du fichier TXT
              },
            };

            // Mise à jour dans le localStorage
            localStorage.setItem(
              "unsavedVideos",
              JSON.stringify(_unsavedVideos)
            );
            setVideoStartTime(start_time);
          } else {
            // Utilisation des données récupérées pour éviter la réinitialisation à 0
            console.log("videoData.start_time => ", start_time);
            setVideoStartTime(videoData.start_time);
          }

          const parsedDuration =
            duration !== undefined
              ? parseFloat(duration).toFixed(1)
              : undefined;
          console.log("parsedDuration===", parsedDuration);
          setDefaultVideoDuration(
            parsedDuration || parseFloat(videoDuration).toFixed(1) || "0"
          );
          setVideoDuration(parseFloat(scene.duration).toFixed(1));

          // // Sauvegarde des données du fichier TXT dans le localStorage
          // _unsavedVideos[index] = {
          //   metadata: {
          //     start_time: videoData.start_time,
          //     duration: videoData.duration,
          //   },
          // };
          // localStorage.setItem("unsavedVideos", JSON.stringify(_unsavedVideos));
        } catch (apiError) {
          console.error("Error fetching video data from TXT API:", apiError);
        }
      }
      setVisibleOptions(index);
      setClickedVideoName(selectedVideo.name);
      setClickedVideoNumero(index + 1);
    } catch (error) {
      console.error("Error fetching template data:", error);
    }
  };

  const handleStartTimeChange = (e) => {
    const newValue = parseFloat(e.target.value);
    console.log("Miovaaaa");
    if (newValue >= 0) {
      // ! Vérifie que la valeur est superieure ou egale a 0
      setVideoStartTime(newValue);
      const index = parseInt(visibleOptions, 10);
      console.log("videos", videos);
      const oldSelectedVideo =
        videos[projectName] && videos[projectName][index];
      const updatedVideoOptions = oldSelectedVideo
        ? { ...oldSelectedVideo }
        : null;
      console.log("oldSelectedVideo = ", oldSelectedVideo);

      if (updatedVideoOptions) {
        // Modifier la valeur de start_time dans copiedVideo
        updatedVideoOptions.metadata = {
          ...(updatedVideoOptions.metadata?.format ||
            updatedVideoOptions.metadata ||
            {}), // Contournement d'erreur
          start_time: newValue.toFixed(1), // Format à une décimale
        };

        console.log("updatedVideo = ", updatedVideoOptions);
      }
      setSelectedVideoTrack(videos[projectName][parseInt(visibleOptions, 10)]);
      setVideo(updatedVideoOptions);

      // Récupère les vidéos non sauvegardées depuis le localStorage
      let _unsavedVideos =
        JSON.parse(localStorage.getItem("unsavedVideos")) || [];
      console.warn("unsavedVideos = ", _unsavedVideos);
      if (_unsavedVideos.length === 0) {
        console.error("Pas de localStorage en cours");

        localStorage.setItem(
          "unsavedVideos",
          JSON.stringify(videos[projectName])
        );
        console.error(
          "localStorage created ...",
          JSON.parse(localStorage.getItem("unsavedVideos"))
        );

        _unsavedVideos = [...videos[projectName]];
      }
      if (index >= 0 && index < _unsavedVideos.length) {
        console.error(
          "localStorage existe",
          JSON.parse(localStorage.getItem("unsavedVideos"))
        );
        _unsavedVideos[index] = updatedVideoOptions;
        // localStorage.setItem("unsavedVideos", JSON.stringify(_unsavedVideos));
        console.warn(
          "[NEW] unsavedVideos localStorage = ",
          JSON.parse(localStorage.getItem("unsavedVideos"))
        );
      } else {
        console.error("Index hors de portée", index);
      }
    }
  };
  const handleStartTimeVideoInput = (event) => {
    event.target.select();
    setVideoStartTime();
  };

  const handleShowDeleteModal = (event) => {
    setShowDeleteModal(true);
  };
  const handleDeleteProject = async (event) => {
    try {
      const response = await fetch(
        `${API_URL}/deleteProjectFolderByFolderName.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            folderName: projectName,
          }),
        }
      );
      console.log("Delete project response = ", response);
      console.log("Delete project response.ok = ", response.ok);
      if (response.ok) {
        try {
          localStorage.removeItem("unsavedVideos"); // Supprimer les modifications non sauvegardees
          navigate("/"); // Redirection vers la page d'accueil
          setShowDeleteModal(false);
        } catch (error) {
          console.error("Generate error:", error);
          alert("Erreur lors de la redirection. Sorry 😢 !");
        }
      } else {
        console.error("Erreur lors de la suppression du projet.");
      }
    } catch (error) {
      console.error("Erreur de réseau:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDurationChange = (e) => {
    const newValue = parseFloat(e.target.value);
    if (newValue >= 0) {
      // ! Vérifie que la valeur est superieure ou egale a 0
      setVideoDuration(newValue);
      const index = parseInt(visibleOptions, 10);
      console.log("videos", videos);
      const oldSelectedVideo =
        videos[projectName] && videos[projectName][index];
      const updatedVideoOptions = oldSelectedVideo
        ? { ...oldSelectedVideo }
        : null;
      console.log("oldSelectedVideo = ", oldSelectedVideo);

      if (updatedVideoOptions) {
        // Modifier la valeur de duration dans copiedVideo
        updatedVideoOptions.metadata = {
          ...(updatedVideoOptions.metadata?.format ||
            updatedVideoOptions.metadata ||
            {}), // Contournement d'erreur
          duration: newValue.toFixed(1), // Format à une décimale
        };

        console.log("updatedVideo = ", updatedVideoOptions);
      }
      setSelectedVideoTrack(videos[projectName][parseInt(visibleOptions, 10)]);
      setVideo(updatedVideoOptions);

      // Récupère les vidéos non sauvegardées depuis le localStorage
      let _unsavedVideos =
        JSON.parse(localStorage.getItem("unsavedVideos")) || [];
      console.warn("_unsavedVideos = ", _unsavedVideos);
      if (_unsavedVideos.length === 0) {
        console.error("Pas de localStorage en cours");

        localStorage.setItem(
          "unsavedVideos",
          JSON.stringify(videos[projectName])
        );
        console.error(
          "localStorage created ...",
          JSON.parse(localStorage.getItem("unsavedVideos"))
        );

        _unsavedVideos = [...videos[projectName]];
      }
      if (index >= 0 && index < _unsavedVideos.length) {
        console.error(
          "localStorage existe",
          JSON.parse(localStorage.getItem("unsavedVideos"))
        );
        _unsavedVideos[index] = updatedVideoOptions;
        localStorage.setItem("unsavedVideos", JSON.stringify(_unsavedVideos));
        console.warn(
          "[NEW] unsavedVideos localStorage = ",
          JSON.parse(localStorage.getItem("unsavedVideos"))
        );
      } else {
        console.error("Index hors de portée", index);
      }
    }
  };

  const handleVideoDurationInput = (event) => {
    event.target.select();
    setVideoDuration();
  };

  const handleTemplateChange = (e) => {
    const selectedTemplate = e.target.value;
    console.log(
      `handleTemplateChange: selectedTemplate === ${selectedTemplate}`
    );
    if (!selectedTemplate) {
      console.error(
        `handleTemplateChange: Invalid selectedTemplate value === ${selectedTemplate}`
      );
      setTemplateName("");
    } else {
      setTemplateName(selectedTemplate);
    }
  };

  const handleShowVideoStartSliderModal = async (event) => {
    event.preventDefault(); // Empêche le comportement par défaut de l'événement si nécessaire

    try {
      // setLoading(true);
      setErr(null);
      let _clickedVideoName;
      if (clickedVideoName === "video_01") {
        // ! Si aucune video n'a ete selectionnee
        _clickedVideoName = "video_01.mp4"; // ! Pour eviter l'erreur sur le nom de la video selectionnee sans extension
      } else {
        _clickedVideoName = clickedVideoName;
      }

      // Récupération des vidéos non sauvegardées depuis le localStorage
      let _unsavedVideos =
        JSON.parse(localStorage.getItem("unsavedVideos")) || [];
      const apiUrl = `${API_URL}/getVideoDataInTXTByFilename.php?filename=${_clickedVideoName}&parentFolderName=${projectName}`;
      const videoDataResponse = await axios.get(apiUrl);
      if (!videoDataResponse || !videoDataResponse.data) {
        return console.error("Error fetching video data from TXT API");
      }
      const videoData = videoDataResponse.data;
      if (_unsavedVideos.length === 0) {
        console.log("Start_time from TXT API =>", videoData.start_time);
        setVideoStartTime(videoData.start_time);
        setCurrent_time(videoData.start_time);
      } else {
        console.warn("Unsaved modifications found");
        const index = visibleOptions;
        console.log("indexLocalStorage", index);
        const _localStorage = JSON.parse(localStorage.getItem("unsavedVideos"));
        const o_localStorage = _localStorage[index] || {};
        console.log("o_localStorage", o_localStorage);
        console.log(
          "o_localStorage.metadata.start_time",
          o_localStorage.metadata.start_time
        );
        const { start_time } = o_localStorage.metadata || {};
        //!FIXX
        if (start_time != null && start_time !== 0 && !isNaN(start_time)) {
          setCurrent_time(start_time);
        } else {
          setCurrent_time(videoData.start_time);
        }
      }
      const response = await axios.get(
        `${API_URL}/getVideoInformationsByVideoFileName.php`,
        {
          params: {
            folderName: projectName,
            fileName: _clickedVideoName,
          },
        }
      );
      if (response.data.status === "success") {
        console.warn("VIDEO DATA ===", response.data.video);
        console.warn(`${API_URL}/${response.data.video.path}`);
        console.warn(
          "VIDEO DATA DURATION ===",
          response.data.video.metadata.duration
        );
        if (visibleOptions === null || visibleOptions === 0) {
          setSelectedVideoUrl(
            `${API_URL}/videos/${projectName}/${_clickedVideoName}?cb=${new Date().getTime()}`
          );
        }
        setSelectedVideoUrl(
          `${API_URL}/${response.data.video.path}?cb=${new Date().getTime()}`
        );
        setSelectedVideoDuration(response.data.video.metadata.duration);
        setVideoData(response.data.video);
      } else {
        setErr(
          response.data.message ||
            "Erreur lors de la récupération des informations."
        );
      }
    } catch (err) {
      setErr("Une erreur est survenue lors de la requête.");
    } finally {
      // setLoading(false);
      setShowVideoStartSliderModal(true);
    }
  };

  const handleCloseModal = () => {
    // Vérifiez si l'élément "unsavedVideos" existe dans le localStorage
    const storedVideos = localStorage.getItem("unsavedVideos");
    const index = visibleOptions;

    // Si localStorage est vide ou invalide
    if (!storedVideos) {
      console.warn(
        "No unsavedVideos found in localStorage. Applying default start time."
      );
      handleDefaultVideoStartTime(index); // Utilisation de la logique par défaut
      setShowVideoStartSliderModal(false); // Fermer le modal ici
      return; // Sortir pour éviter d'autres erreurs
    }

    // Si localStorage est non vide, analysez le contenu
    const _localStorage = JSON.parse(storedVideos);

    // Vérifiez que l'index est valide
    if (index < 0 || index >= _localStorage.length) {
      console.warn("Invalid index provided. Applying default start time.");
      handleDefaultVideoStartTime(index); // Utilisation de la logique par défaut
      return;
    }

    // Si tout est correct, récupérez les métadonnées
    const o_localStorage = _localStorage[index] || {};
    console.log("o_localStorage", o_localStorage);

    if (o_localStorage.metadata) {
      // Vérifier si 'start_time' est directement dans 'metadata'
      if (typeof o_localStorage.metadata.start_time !== "undefined") {
        console.log(
          "o_localStorage.metadata.start_time",
          o_localStorage.metadata.start_time
        );
        setVideoStartTime(parseFloat(o_localStorage.metadata.start_time));
      }
      // Sinon, vérifier s'il est dans 'metadata.format'
      else if (o_localStorage.metadata?.format?.start_time !== undefined) {
        console.log(
          "o_localStorage.metadata?.format?.start_time",
          o_localStorage.metadata?.format?.start_time
        );
        setVideoStartTime(
          parseFloat(o_localStorage.metadata.format.start_time)
        );
      }
      // Si aucun 'start_time' n'est trouvé, appliquer la logique par défaut
      else {
        console.warn("Metadata start_time missing. Applying default logic.");
        handleDefaultVideoStartTime(); // Logique par défaut si 'start_time' est manquant
      }
    } else {
      console.warn("Metadata object missing. Applying default logic.");
      handleDefaultVideoStartTime(index); // Logique par défaut si 'metadata' est manquant
    }

    setShowVideoStartSliderModal(false); // Fermer le modal
  };

  // Fonction utilitaire pour gérer les valeurs par défaut de videoStartTime
  const handleDefaultVideoStartTime = (index) => {
    console.log("VIDEO START TIME : ", videoStartTime);

    if (typeof videoStartTime === "undefined") {
      console.warn("videoStartTime is undefined, setting to 0");
      setVideoStartTime(0.0); // Valeur par défaut si undefined
    } else if (isNaN(videoStartTime)) {
      console.error("videoStartTime is not a number, resetting to 0");
      setVideoStartTime(0.0); // Valeur par défaut si non-numérique
    } else if (videoStartTime < 0) {
      console.error("videoStartTime is negative, resetting to 0");
      setVideoStartTime(0.0); // Valeur par défaut si négatif
    } else {
      console.warn(
        "videoStartTime defined, mais doit etre remise a la valeur par defaut"
      );
      try {
        const videoFilename = videos?.[projectName]?.[index];
        console.log("ici videoFilename = ", videoFilename);
        if (typeof videoFilename.metadata.start_time !== "undefined") {
          console.log(
            "ici videoFilename.metadata.start_time = ",
            videoFilename.metadata?.start_time
          );
          setVideoStartTime(
            parseFloat(videoFilename.metadata?.start_time.toFixed(1))
          ); //!a changer
        } else if (videoFilename.metadata?.format?.start_time !== undefined) {
          console.log(
            "ici videoFilename.metadata?.format?.start_time = ",
            videoFilename.metadata?.format?.start_time
          );
          setVideoStartTime(videoFilename.metadata?.format?.start_time); //!a changer
        }
      } catch {}

      // setVideoStartTime(parseFloat(videoStartTime.toFixed(1))); //!a changer
    }
  };

  // Fonction pour mettre à jour le temps actuel de la vidéo
  // const progressVideo = (e) => {
  //   e.preventDefault();
  //   setCurrent_time(Math.round(e.target.value));
  //   const video = refVideo.current;
  //   if (video) {
  //     video.currentTime = Math.round(e.target.value);
  //   }
  // };
  const progressVideo = (e) => {
    const newTime = Math.round(e.target.value);
    setCurrent_time(newTime);

    const video = refVideo.current;
    if (video) {
      video.currentTime = newTime;
    }
  };

  // Fonction pour charger les métadonnées de la vidéo
  const loadedMetadata = () => {
    const video = refVideo.current;
    if (video) {
      const duration = video.duration;
      setTotalDuration(duration);
    }
  };

  // stopVideo et onPlayVideo
  const stopVideo = () => {
    const video = refVideo.current;
    if (video) {
      video.pause();
      video.removeEventListener("timeupdate", updateSlider); // Retire l'écouteur lorsque la vidéo est arrêtée

      video.currentTime = 0;
    }
  };

  const onPlayVideo = () => {
    const video = refVideo.current;
    if (video) {
      video.play();
      video.addEventListener("timeupdate", updateSlider); // Ajoute un écouteur pour mettre à jour le slider en temps réel
    }
  };

  const updateSlider = () => {
    const video = refVideo.current;
    if (video) {
      const currentSeconds = video.currentTime;
      setCurrent_time(currentSeconds);
      setTotal_secs(currentSeconds.toFixed(1));
      setVideoStartTime(currentSeconds); // !Mettre à jour le temps de début de la vidéo
    }
  };

  const handleInputChange = (e) => {
    const newTime = parseFloat(e.target.value);
    console.log("url ato", selectedVideoUrl);
    const video = refVideo.current;
    video.currentTime = newTime;
    console.log("currentTime", video.currentTime);
    setCurrent_time(newTime); // Mise à jour en temps réel du curseur
    // setVideoStartTime(newTime); // Mettre à jour le temps de début de la vidéo
  };
  const handleMouseUp = (e) => {
    const video = refVideo.current;
    if (video) {
      const newTime = parseFloat(e.target.value);
      video.currentTime = newTime;

      console.log("New time after mouse up:", newTime);
      setCurrent_time(newTime);
      setVideoStartTime(newTime); // !Mettre à jour le temps de début de la vidéo
    }
  };

  const handleUpdateVideoStartTime = () => {
    //! SET VIDEO START TIME
    console.log("VIDEO START TIME : ", videoStartTime);
    if (typeof videoStartTime === "undefined") {
      console.warn("videoStartTime is undefined, setting to 0");
      setVideoStartTime(0.0); // Valeur par défaut
    } else if (isNaN(videoStartTime)) {
      console.error("videoStartTime is not a number, resetting to 0");
      setVideoStartTime(0.0); // Valeur par défaut si non-numérique
    } else if (videoStartTime < 0) {
      console.error("videoStartTime is negative, resetting to 0");
      setVideoStartTime(0.0); // Valeur par défaut si négatif
    } else {
      setVideoStartTime(parseFloat(videoStartTime.toFixed(1))); // Assurez-vous qu'il s'agit d'un nombre valide
    }

    const index = parseInt(visibleOptions, 10);
    console.log("videos", videos);
    const oldSelectedVideo = videos[projectName] && videos[projectName][index];
    const updatedVideoOptions = oldSelectedVideo
      ? { ...oldSelectedVideo }
      : null;
    console.log("oldSelectedVideo = ", oldSelectedVideo);
    console.log("videoStartTime = ", videoStartTime);
    console.log("current_time = ", current_time);

    if (updatedVideoOptions) {
      if (videoStartTime === undefined) {
        // Modifier la valeur de start_time dans copiedVideo
        updatedVideoOptions.metadata = {
          ...(updatedVideoOptions.metadata?.format ||
            updatedVideoOptions.metadata ||
            {}), // Contournement d'erreur
          start_time: parseFloat(current_time).toFixed(1),
        };
      } else {
        //!FIXX
        // Modifier la valeur de start_time dans copiedVideo
        updatedVideoOptions.metadata = {
          ...(updatedVideoOptions.metadata?.format ||
            updatedVideoOptions.metadata ||
            {}), // Contournement d'erreur
          start_time: videoStartTime.toFixed(1),
        };
      }
      console.log("updatedVideo = ", updatedVideoOptions);
    }
    setSelectedVideoTrack(videos[projectName][index]);
    setVideo(updatedVideoOptions);

    // Récupère les vidéos non sauvegardées depuis le localStorage
    let _unsavedVideos =
      JSON.parse(localStorage.getItem("unsavedVideos")) || [];
    console.warn("unsavedVideos = ", _unsavedVideos);

    if (_unsavedVideos.length === 0) {
      console.warn(
        "Pas de localStorage en cours, création d'une nouvelle entrée"
      );
      localStorage.setItem(
        "unsavedVideos",
        JSON.stringify(videos[projectName])
      );
      _unsavedVideos = [...videos[projectName]];
    }

    if (index >= 0 && index < _unsavedVideos.length) {
      console.log("Mise à jour de la vidéo dans le localStorage");
      _unsavedVideos[index] = updatedVideoOptions;
      localStorage.setItem("unsavedVideos", JSON.stringify(_unsavedVideos));
      console.warn(
        "[NEW] unsavedVideos localStorage = ",
        JSON.parse(localStorage.getItem("unsavedVideos"))
      );
    } else {
      console.error("Index hors de portée", index);
    }

    setShowVideoStartSliderModal(false); // Fermer le modal ici
  };

  const handleImportNewVideos = async () => {
    if (!fileInputRef.current.files.length) {
      setErr("Veuillez sélectionner des fichiers avant d'importer.");
      return;
    }
    const _nbExistingVideos = parseInt(
      videoTrackList["projects"][projectName].length,
      10
    );
    const _nbImportedVideos = parseInt(fileInputRef.current.files.length, 10);
    const _sum = parseInt(_nbExistingVideos + _nbImportedVideos, 10);
    console.log("_nbExistingVideos + _nbImportedVideos = ", _sum);

    let n_maxVideoTemplate;

    try {
      console.warn(
        `${API_URL}/getLimitationTemplate.php?templateName=${templateName}`
      );
      const getLimitationResponse = await axios.get(
        `${API_URL}/getLimitationTemplate.php?templateName=${templateName}`
      );
      const data = getLimitationResponse.data;
      if (data && data.maxComment) {
        n_maxVideoTemplate = data.maxComment;
      } else {
        console.error("La réponse de l'API est invalide ou manquante.");
      }
    } catch (error) {}
    console.log("n_maxVideoTemplate = ", n_maxVideoTemplate);

    console.warn(`OK, go for processing handleImportNewVideos`);
    const formData = new FormData();
    formData.append("projectName", projectName);
    setLoading(true);

    for (let i = 0; i < fileInputRef.current.files.length; i++) {
      formData.append("videoFiles[]", fileInputRef.current.files[i]);
    }
    try {
      const response = await axios.post(
        `${API_URL}/importVideosInASpecificProject.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.status === 1) {
        console.log(
          "Files uploaded successfully:",
          response.data.uploadedFiles
        );
        fetchData();
      } else {
        setErr(
          response.data.message ||
            "Une erreur est survenue lors du téléchargement."
        );
      }
    } catch (error) {
      setErr("Une erreur est survenue lors du téléchargement.");
      console.error("Error uploading files:", error);
    } finally {
      resetForm();
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const fileNames = files.map((file) => file.name);
    setFileNames(fileNames);
  };

  const handleAddingMultipleVideo = async () => {
    const videoNames = selectedVideos.map((item) => item.videoName);
    console.log("selectedVideos = ", videoNames);

    // Affichage pour vérification
    const _projectName =
      selectedVideos.length > 0 ? selectedVideos[0].projectName : "";
    console.log("Nom du projet de la vidéo ajoutée =", _projectName);

    const _nbSelectedVideos = parseInt(selectedVideos.length, 10);
    const _nbExistingVideos = parseInt(
      videoTrackList["projects"][projectName].length,
      10
    );
    const _sum = parseInt(_nbExistingVideos + _nbSelectedVideos, 10);
    console.log("_nbExistingVideos + _nbSelectedVideos = ", _sum);
    let n_maxVideoTemplate;
    try {
      console.warn(
        `${API_URL}/getLimitationTemplate.php?templateName=${templateName}`
      );
      const getLimitationResponse = await axios.get(
        `${API_URL}/getLimitationTemplate.php?templateName=${templateName}`
      );
      const data = getLimitationResponse.data;
      if (data && data.maxComment) {
        n_maxVideoTemplate = data.maxComment;
      } else {
        console.error(
          "La réponse de /getLimitationTemplate.php est invalide ou manquante."
        );
      }
    } catch (error) {}
    console.log("n_maxVideoTemplate = ", n_maxVideoTemplate);
    if (_sum <= n_maxVideoTemplate) {
      console.log(`Le nombre de vidéos à ajouter est valide pour ce template`);
      setLoading(true);
      try {
        for (let videoName of videoNames) {
          console.log("Nom de la vidéo ajoutée =", videoName);
          console.log("Nom du projet de la vidéo ajoutée =", _projectName);
          console.log("Nom du projet actuel =", paramProjectName);

          let videoNameTXT = videoName;
          if (_projectName !== paramProjectName) {
            console.warn(
              `${API_URL}/copyVideoFileAndThumbnailFromOtherProject.php`
            );
            // Appel à l'API pour copier la vidéo et la miniature
            const copyResponse = await fetch(
              `${API_URL}/copyVideoFileAndThumbnailFromOtherProject.php`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  originProjectName: _projectName,
                  destinationProjectName: paramProjectName,
                  videoFilename: videoName,
                }),
              }
            );
            const copyData = await copyResponse.json();
            if (copyResponse.ok) {
              console.log(
                "Success /copyVideoFileAndThumbnailFromOtherProject.php"
              );
              videoNameTXT = copyData.newFileName;
            } else {
              console.error(
                "Error on /copyVideoFileAndThumbnailFromOtherProject.php"
              );
              throw new Error(
                copyData.message ||
                  "Error on /copyVideoFileAndThumbnailFromOtherProject.php"
              );
            }
          }
          console.warn(
            `${API_URL}/copyVideoFileAndThumbnailFromOtherProject.php`
          );
          const response = await fetch(
            `${API_URL}/addVideoInTxtFileByVideoFileName.php`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                sourceFolderName: _projectName,
                destinationFolderName: paramProjectName,
                video: videoNameTXT,
              }),
            }
          );
          if (response.ok) {
            try {
              console.warn(`${API_URL}/generate.php`);
              setLoading(true);
              const _response = await axios.post(`${API_URL}/generate.php`, {
                parentFolderName: paramProjectName,
                templateName: templateName,
              });
              if (_response.status === 200) {
                fetchData(); // Recharge les données sans recharger la page
              } else {
                alert(_response.data.message);
              }
            } catch (error) {
              console.error("Generate error:", error);
              alert("Error on /generate.php");
            } finally {
              setLoading(false);
            }
          } else {
            console.error("Error on /addVideoInTxtFileByVideoFileName.php");
          }
        }
      } catch (error) {
        console.error("Erreur :", error);
        alert(`Erreur : ${error.message}`);
      } finally {
        setLoading(false);
      }
    } else {
      console.error(
        `Le nombre de video depasse le nombre maximal pour le ${templateName}`
      );
      alert(
        "Le nombre de vidéos dépasse le nombre maximal autorisé pour ce template."
      );
    }
  };

  const resetSelectedVideos = async () => {
    setSelectedVideos([]);
  };

  const resetForm = () => {
    setFileNames([]);
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div
              className="row"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <div className="col">
                {loading ? (
                  <div></div>
                ) : videoUrl ? (
                  <div>
                    <div
                      className="card card-secondary card-widget"
                      style={{ height: "600px" }}
                    >
                      <div className="card-header">
                        <strong>
                          <span className="username">Liste des vidéos</span>
                        </strong>
                      </div>
                      <div
                        className="card-body"
                        style={{ display: "block", overflowY: "auto" }}
                      >
                        <div className="container">
                          {loading ? (
                            <div></div>
                          ) : videoUrl ? (
                            <div>
                              <div>
                                <div className="row">
                                  <div className="col-12">
                                    <small>
                                      <strong>
                                        {JSON.stringify(selectedVideos.length)}{" "}
                                        vidéo(s) sélectionné(s)
                                      </strong>
                                    </small>

                                    <div className="input-group mb-2">
                                      <div className="custom-file">
                                        <input
                                          type="file"
                                          className="custom-file-input"
                                          id="videoFiles"
                                          name="videoFiles[]"
                                          multiple
                                          accept="video/*"
                                          ref={fileInputRef}
                                          onChange={handleFileChange}
                                          style={{
                                            height: "30px",
                                            padding: "5px 10px",
                                          }}
                                        />
                                        <label
                                          className="custom-file-label"
                                          htmlFor="videoFiles"
                                        >
                                          {fileNames.length > 0
                                            ? fileNames.join(", ")
                                            : "Choisissez les fichiers"}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="mb-2">
                                      <button
                                        type="button"
                                        className="btn btn-block btn-warning btn-flat btn-sm"
                                        onClick={() => handleImportNewVideos()}
                                      >
                                        Importer
                                      </button>
                                    </div>
                                    {selectedVideos.length !== 0 && (
                                      <div className="mb-2">
                                        <button
                                          type="button"
                                          className="btn btn-block btn-info btn-flat btn-sm"
                                          onClick={() =>
                                            handleAddingMultipleVideo()
                                          }
                                        >
                                          Effectuer l'ajout multiple
                                        </button>
                                      </div>
                                    )}

                                    {err && (
                                      <div className="alert alert-danger">
                                        {err}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex flex-column align-items-center">
                                <div className="mb-2 w-100">
                                  <div id="accordion">
                                    {Object.entries(projects).map(
                                      ([projectName, videos], projectIndex) => {
                                        const isOpen =
                                          projectName === paramProjectName;

                                        return (
                                          <div
                                            className="card card-dark shadow"
                                            key={projectIndex}
                                          >
                                            <button
                                              className="btn btn-block btn-dark btn-xs"
                                              data-toggle="collapse"
                                              data-target={`#collapse${projectIndex}`}
                                              aria-expanded={
                                                isOpen ? "true" : "false"
                                              }
                                              aria-controls={`collapse${projectIndex}`}
                                              onClick={() =>
                                                resetSelectedVideos()
                                              }
                                            >
                                              <i className="fas fa-bars mr-2"></i>
                                              <strong>{projectName}</strong>
                                            </button>
                                            <div
                                              id={`collapse${projectIndex}`}
                                              className={`collapse ${
                                                isOpen ? "show" : ""
                                              }`}
                                              aria-labelledby={`heading${projectIndex}`}
                                              data-parent="#accordion"
                                            >
                                              <div className="card-body bg-light">
                                                <div className="row">
                                                  {videos.length > 0 ? (
                                                    videos.map(
                                                      (video, index) => (
                                                        <div
                                                          className="col-6"
                                                          key={index}
                                                        >
                                                          <div className="card video-container position-relative">
                                                            <div>
                                                              <div className="position-relative">
                                                                <img
                                                                  src={`${API_URL}/${
                                                                    video.thumbnail
                                                                  }?cb=${new Date().getTime()}`}
                                                                  alt={
                                                                    video.name
                                                                  }
                                                                  className="card-img-top"
                                                                  style={{
                                                                    width:
                                                                      "100%", // largeur
                                                                    height:
                                                                      "80px", // hauteur
                                                                    objectFit:
                                                                      "cover", // remplissage du conteneur sans déformer
                                                                  }}
                                                                />
                                                                <input
                                                                  type="checkbox"
                                                                  className="position-absolute"
                                                                  style={{
                                                                    top: "5px",
                                                                    left: "5px",
                                                                    zIndex: "1",
                                                                  }}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    const checked =
                                                                      e.target
                                                                        .checked;
                                                                    setSelectedVideos(
                                                                      (
                                                                        prevSelected
                                                                      ) => {
                                                                        if (
                                                                          checked
                                                                        ) {
                                                                          return [
                                                                            ...prevSelected,
                                                                            {
                                                                              videoName:
                                                                                video.name,
                                                                              projectName:
                                                                                projectName,
                                                                            },
                                                                          ];
                                                                        } else {
                                                                          return prevSelected.filter(
                                                                            (
                                                                              item
                                                                            ) =>
                                                                              !(
                                                                                item.videoName ===
                                                                                  video.name &&
                                                                                item.projectName ===
                                                                                  projectName
                                                                              )
                                                                          );
                                                                        }
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                              <div className="">
                                                                <button
                                                                  type="button"
                                                                  className="btn btn-block btn-secondary btn-xs"
                                                                  onClick={() =>
                                                                    handleAddingVideo(
                                                                      projectName,
                                                                      video.name
                                                                    )
                                                                  }
                                                                >
                                                                  Ajouter
                                                                </button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )
                                                    )
                                                  ) : (
                                                    <div></div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <p></p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p></p>
                )}
              </div>
              <div className="col-6">
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div
                      className="text-center"
                      style={{
                        marginTop: "250px",
                      }}
                    >
                      <div
                        className="spinner-border text-warning"
                        role="status"
                      ></div>
                      <br />
                      <div>
                        <small>
                          <strong>
                            Processus de régénération en cours. Merci de votre
                            patience. Toute interruption pourrait entraîner des
                            pertes de données.
                          </strong>
                        </small>
                      </div>
                    </div>
                  </div>
                ) : videoUrl ? (
                  <div
                    className="w-100 h-100"
                    style={{
                      position: "relative",
                      maxWidth: "100%",
                      backgroundColor: "#f8f9fa",
                      overflow: "hidden",
                      border: "3px solid #f8f9fa",
                      boxShadow: "2px 2px 5px 2px #e6e6e7",
                    }}
                  >
                    <ReactPlayer
                      url={videoUrl}
                      controls={true}
                      width="100%"
                      height="100%"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <div
                      className="text-center"
                      style={{
                        marginTop: "250px",
                      }}
                    >
                      <h5>
                        <i className="far fa-sad-tear text-danger" /> Oups ! Un
                        problème s'est produit.
                      </h5>

                      <p>
                        <code>
                          Erreur 500 lors de la génération de la vidéo : le
                          fichier final n'a pas été trouvé.
                        </code>
                      </p>
                      <p>
                        <small className="text-danger">
                          Actuellement, une importation simultanée de plus de 24
                          vidéos peut générer des erreurs au niveau du rendu.
                        </small>
                      </p>
                      <p>
                        <small>
                          <strong>
                            Nous nous efforcerons d'y remédier immédiatement.
                          </strong>
                        </small>
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="col">
                <div className="row">
                  {loading ? (
                    <div></div>
                  ) : videoUrl ? (
                    <div className="col-12">
                      <div className="form-group w-100">
                        <label htmlFor="video-name">
                          Nom de la vidéo téléchargée :
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-border"
                          id="video-name"
                          placeholder="final_video"
                          value={videoName}
                          onChange={(e) => setVideoName(e.target.value)}
                        />
                      </div>
                      <button
                        className="btn btn-secondary btn-block btn-flat btn-sm"
                        onClick={handleDownload}
                      >
                        <i className="fas fa-cloud-download-alt"></i>{" "}
                        Télécharger
                      </button>
                      <div className="col-12" style={{ marginTop: "8px" }}>
                        <div className="card card-secondary shadow-lg">
                          <div className="card-header">
                            <strong>Template du projet</strong>
                          </div>
                          <div className="card-body">
                            <div className="form-group">
                              <select
                                className="form-control form-control-sm"
                                id="exampleSelectRounded0"
                                value={templateName} // Associe la valeur sélectionnée au templateName
                                onChange={handleTemplateChange}
                              >
                                <option value="template1">Template 1</option>
                                <option value="template2">Template 2</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {visibleOptions !== null && (
                        <div className="col-12" style={{ marginTop: "8px" }}>
                          <div className="card card-secondary card-widget">
                            <div className="card-header">
                              <strong>
                                {/* Options de la vidéo: {clickedVideoNumero} */}
                                Options de la vidéo: {visibleOptions + 1}
                              </strong>
                              <div className="card-tools">
                                <button
                                  type="button"
                                  className="btn btn-tool"
                                  data-card-widget="remove"
                                >
                                  <i className="fas fa-times" />
                                </button>
                              </div>
                            </div>
                            <div className="card-footer card-comments">
                              <div
                                className="input-group input-group-sm"
                                style={{
                                  paddingBottom: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="input-group-prepend">
                                  <p
                                    className="input-group-text"
                                    style={{ flexShrink: 0 }}
                                  >
                                    <i className="far fa-play-circle"></i>
                                    &nbsp;Début de la vidéo :&nbsp;
                                  </p>
                                </div>
                                <input
                                  type="number"
                                  step="0.1"
                                  className="form-control"
                                  value={
                                    videoStartTime !== undefined
                                      ? videoStartTime
                                      : current_time !== undefined
                                      ? current_time
                                      : ""
                                  }
                                  min={0}
                                  data-toggle="modal"
                                  data-target="#exampleModalCenterStartTime"
                                  onChange={(e) => handleStartTimeChange(e)}
                                  onFocus={handleStartTimeVideoInput}
                                  onClick={handleShowVideoStartSliderModal}
                                  style={{ flexGrow: 1 }}
                                />
                                {showVideoStartSliderModal && (
                                  <div>
                                    {/* Overlay */}
                                    <div
                                      className={`modal-overlay ${
                                        showVideoStartSliderModal ? "show" : ""
                                      }`}
                                      onClick={handleCloseModal}
                                    ></div>
                                    <div
                                      className="modal fade show"
                                      id="exampleModalCenterStartTime"
                                      tabIndex={-1}
                                      role="dialog"
                                      aria-labelledby="exampleModalCenterTitle"
                                      // aria-hidden="true"
                                      inert={
                                        showVideoStartSliderModal
                                          ? undefined
                                          : "true"
                                      } // Utiliser undefined pour omettre l'attribut
                                      style={{
                                        display: showVideoStartSliderModal
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      <div
                                        className="modal-dialog modal-dialog-centered"
                                        role="document"
                                      >
                                        <div className="modal-content">
                                          <div className="modal-header bg-warning">
                                            <h5
                                              className="modal-title"
                                              id="exampleModalLongTitle"
                                            >
                                              <i className="fas fa-cut"></i>{" "}
                                              &nbsp;Modification de début de la
                                              vidéo
                                            </h5>
                                            <button
                                              type="button"
                                              className="close"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                              onClick={handleCloseModal}
                                            >
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div className="modal-body">
                                            <div
                                              className="d-flex justify-content-center align-items-center bg-dark"
                                              style={{
                                                height: "100%",
                                                overflow: "hidden",
                                                border: "1px solid #000",
                                              }}
                                            >
                                              <div className="text-center">
                                                <video
                                                  width="40%"
                                                  height="auto"
                                                  className="video-preview"
                                                  //controls
                                                  key={clickedVideoName}
                                                  ref={refVideo}
                                                  //autoPlay
                                                  // playsInline
                                                  muted
                                                  onEnded={stopVideo}
                                                  // onPlay={onPlayVideo}
                                                  onLoadedMetadata={
                                                    loadedMetadata
                                                  }
                                                >
                                                  <source
                                                    src={selectedVideoUrl}
                                                    type="video/mp4"
                                                  />
                                                </video>
                                              </div>
                                            </div>

                                            <div className="row">
                                              <div className="col-sm">
                                                <label htmlFor="startTimeSlider">
                                                  <small>
                                                    <strong>
                                                      Choisissez le début de la
                                                      vidéo :
                                                    </strong>
                                                  </small>{" "}
                                                </label>
                                              </div>
                                              <div className="col-sm">
                                                <input
                                                  type="range"
                                                  className="form-range"
                                                  min="0.1"
                                                  max={totalDuration}
                                                  step="0.1"
                                                  // onChange={progressVideo}
                                                  onTouchEnd={handleMouseUp} // écrans tactiles
                                                  onMouseUp={handleMouseUp} // Mise à jour de la vidéo lorsque l'utilisateur relâche la souris
                                                  onInput={handleInputChange}
                                                  onClick={handleInputChange}
                                                  value={current_time}
                                                  style={{
                                                    width: "100%",
                                                    height: "8px",
                                                    background: "#ffc107",
                                                    borderRadius: "5px",
                                                    outline: "none",
                                                    WebkitAppearance: "none",
                                                    appearance: "none",
                                                  }}
                                                />
                                                <span>
                                                  {" "}
                                                  <small>
                                                    <strong>
                                                      {/* {current_time.toFixed(1)} */}
                                                      {current_time}
                                                    </strong>
                                                    &nbsp;sec
                                                  </small>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-light btn-sm"
                                              data-dismiss="modal"
                                              onClick={() => {
                                                handleCloseModal();
                                              }}
                                            >
                                              <i className="far fa-times-circle"></i>
                                              &nbsp;Abandonner
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-warning btn-sm"
                                              onClick={
                                                handleUpdateVideoStartTime
                                              }
                                            >
                                              <i className="far fa-check-circle"></i>
                                              &nbsp;Conserver les modifications
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div
                                className="input-group input-group-sm"
                                style={{
                                  paddingBottom: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="input-group-prepend">
                                  <p
                                    className="input-group-text"
                                    style={{ flexShrink: 0 }}
                                  >
                                    <i className="far fa-clock" />
                                    &nbsp;Durée réelle :&nbsp;
                                  </p>
                                </div>
                                <input
                                  type="number"
                                  step="0.1"
                                  className="form-control"
                                  value={
                                    defaultVideoDuration !== undefined
                                      ? defaultVideoDuration
                                      : ""
                                  }
                                  min={1}
                                  disabled
                                  style={{ flexGrow: 1 }}
                                />
                              </div>

                              <div
                                className="input-group input-group-sm"
                                style={{
                                  paddingBottom: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="input-group-prepend">
                                  <p
                                    className="input-group-text"
                                    style={{ flexShrink: 0 }}
                                  >
                                    <i className="fas fa-cogs" />
                                    &nbsp;Durée après configuration :&nbsp;
                                  </p>
                                </div>
                                <input
                                  type="number"
                                  step="0.1"
                                  className="form-control"
                                  value={
                                    videoDuration !== undefined
                                      ? videoDuration
                                      : ""
                                  }
                                  min={1}
                                  // onChange={(e) => handleDurationChange(e)}
                                  // onFocus={handleVideoDurationInput}
                                  disabled
                                  style={{ flexGrow: 1 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <p></p>
                  )}
                </div>

                {!loading && videoUrl && (
                  <div className="row flex-row align-items-end">
                    <div className="col-12" style={{ paddingTop: "8px" }}>
                      <button
                        type="button"
                        className="btn btn-block btn-warning btn-sm w-100"
                        onClick={handleChangeOrder}
                      >
                        <i className="far fa-save"></i>&nbsp;Valider les
                        modifications
                      </button>
                    </div>
                    <div className="col-12" style={{ paddingTop: "8px" }}>
                      <button
                        type="button"
                        className="btn btn-block btn-dark btn-sm w-100"
                        onClick={handleShowDeleteModal}
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                      >
                        <i className="far fa-trash-alt"></i>&nbsp;Supprimer le
                        projet
                      </button>

                      {showDeleteModal && (
                        <div
                          className="modal fade"
                          id="exampleModalCenter"
                          tabIndex={-1}
                          role="dialog"
                          aria-labelledby="exampleModalCenterTitle"
                          aria-hidden="true"
                        >
                          <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-header bg-warning">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLongTitle"
                                >
                                  <i className="fas fa-exclamation-triangle"></i>
                                  &nbsp;Confirmation de suppression
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <h6>
                                  <small>
                                    <strong>
                                      Cela va effacer définitivement cet
                                      élément. Es-tu prêt(e) à tourner la page ?
                                    </strong>
                                  </small>
                                </h6>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-light btn-sm"
                                  data-dismiss="modal"
                                >
                                  <i className="far fa-times-circle"></i>
                                  &nbsp;Oups, non !
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-warning btn-sm"
                                  data-dismiss="modal"
                                  onClick={handleDeleteProject}
                                >
                                  <i className="far fa-check-circle"></i>
                                  &nbsp;Oui, je suis sûr(e)
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {loading ? (
              <div></div>
            ) : videoUrl ? (
              <div
                className="row"
                style={{
                  backgroundColor: "#343a40",
                  borderRadius: "15px",
                  overflowX: "auto",
                  overflowY: "hidden",
                  whiteSpace: "nowrap",
                  height: "194px", // hauteur de la piste video et audio
                  paddingBottom: "20px",
                }}
              >
                <div>
                  <div
                    className="col"
                    style={{
                      paddingTop: "4px",
                    }}
                  >
                    <div className="row">
                      <div>
                        <div className="col-12 col-sm-8 col-md-10">
                          <div className="row">
                            <div
                              className="d-flex flex-row justify-content-start w-100"
                              ref={videosContainerRef}
                            >
                              <>
                                {Object.entries(videos).flatMap(
                                  ([projectName, videoArray]) => {
                                    if (Array.isArray(videoArray)) {
                                      return videoArray.map((video, index) => (
                                        <div key={index}>
                                          <div>
                                            <div
                                              className="justify-content-around"
                                              style={{
                                                padding: "4px",
                                              }}
                                            >
                                              {/* INPUT ORDRE */}
                                              <input
                                                type="number"
                                                className="form-control is-warning"
                                                id={`exampleInputRounded${index}`}
                                                // placeholder={index + 1}
                                                // value={
                                                //   inputOrderValues[index] ||
                                                //   index + 1
                                                // }
                                                //defaultValue={getDefaultValue(index)}
                                                value={getDefaultValue(index)}
                                                style={{
                                                  borderRadius: "12px",
                                                  width: "90px",
                                                  maxWidth: "90px",
                                                }}
                                                ref={(el) =>
                                                  (inputRefs.current[index] =
                                                    el)
                                                }
                                                onChange={(event) =>
                                                  handleInputOrderChange(
                                                    index,
                                                    event,
                                                    videos
                                                  )
                                                }
                                                onKeyDown={(event) =>
                                                  handleKeyDown(
                                                    index,
                                                    event,
                                                    videos
                                                  )
                                                }
                                                /* onBlur={(event) =>
                                              handleInputOrderChange(
                                                index,
                                                event,
                                                videos
                                              )
                                            }*/
                                                onFocus={handleFocus}
                                              />
                                            </div>
                                          </div>
                                          <div
                                            className="d-flex flex-column align-items-center video-container"
                                            style={{
                                              position: "relative",
                                              padding: "8px",
                                              paddingBlock: "10px",
                                              height: "100px",
                                              width: "100px",
                                              display: "inline-block",
                                              borderRadius: "8px", 
                                              border:
                                                visibleOptions === index
                                                  ? "2px solid #ffc107"
                                                  : "none", 
                                              transition:
                                                "border 0.3s ease-in-out",
                                            }}
                                            onMouseEnter={(e) => {
                                              e.currentTarget.querySelector(
                                                "img"
                                              ).style.opacity = "0.5";
                                              e.currentTarget.querySelector(
                                                "img"
                                              ).style.borderRadius =
                                                "0px 0px 8px 8px";
                                              e.currentTarget.querySelector(
                                                "input"
                                              ).style.display = "block";
                                            }}
                                            onMouseLeave={(e) => {
                                              e.currentTarget.querySelector(
                                                "img"
                                              ).style.opacity = "1";
                                              e.currentTarget.querySelector(
                                                "img"
                                              ).style.borderRadius =
                                                "8px 8px 8px 8px";
                                              e.currentTarget.querySelector(
                                                "input"
                                              ).style.display = "none";
                                            }}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setVisibleOptions(index); 
                                              handleClick(index, event, videos);
                                            }}
                                          >
                                            <input
                                              className="btn btn-block btn-warning btn-xs btn-flat"
                                              type="button"
                                              value={"Supprimer"}
                                              style={{
                                                position: "absolute",
                                                top: "0",
                                                left: "0",
                                                display: "none",
                                                zIndex: "10",
                                              }}
                                              onClick={(event) => {
                                                event.stopPropagation(); // Empêche la propagation de l'événement
                                                handleDelete(
                                                  projectName,
                                                  video.name
                                                );
                                              }}
                                            />
                                            <img
                                              src={`${API_URL}/${
                                                video.thumbnail
                                              }?cb=${new Date().getTime()}`}
                                              alt={video.name}
                                              className="card-img-top"
                                              style={{
                                                borderRadius: "8px 8px 8px 8px",
                                                objectFit: "cover",
                                                height: "100%",
                                                width: "100%",
                                              }}
                                            />
                                            <div
                                              style={{
                                                position: "absolute",
                                                bottom: "6px",
                                                right: "12px",
                                                color: "white",
                                                textShadow:
                                                  "1px 1px 5px rgba(0, 0, 0, 0.7)",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {parseFloat(
                                                video.metadata?.format
                                                  ?.duration ||
                                                  video.metadata?.duration ||
                                                  "erreur"
                                              ).toFixed(1) + " s"}
                                            </div>
                                          </div>
                                        </div>
                                      ));
                                    } else {
                                      console.error(
                                        `Expected videoArray to be an array, but got ${typeof videoArray}`
                                      );
                                      return [];
                                    }
                                  }
                                )}
                              </>
                              <div
                                className="d-flex w-50"
                                style={
                                  {
                                    // padding: "10px", // fixe pour suivre la taille de la liste de videos
                                  }
                                }
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100"></div>
                  <div className="col" style={{paddingTop: "5px"}}>
                    <div
                      className="progress"
                      style={{
                        height: "35px",
                        borderRadius: "8px",
                        width: progressBarWidth,
                      }}
                    >
                      <div
                        className="progress-bar bg-indigo progress-bar-striped"
                        role="progressbar"
                        style={{ width: "100%" }}
                      >
                        audio.mp3
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p></p>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default VideoEditor;
