import React from "react";

function ContentHeader() {
  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item active">Importation des vidéos</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentHeader;
