import React from "react";
import { useNavigate } from "react-router-dom";

function MainHeader() {
  const navigate = useNavigate();

  const handleImportVideoClick = () => {
    localStorage.removeItem('unsavedVideos');
    localStorage.removeItem('selectedProject');
    navigate("/");
  };

  const handleVideoEditClick = () => {
    navigate("/video-editor");
  };

  return (
    <div>
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <a href="#" className="nav-link" onClick={handleImportVideoClick}>
              Importation des vidéos
            </a>
          </li>
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Dropdown Menu */}
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="fas fa-chevron-circle-down fa-lg" />
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">Menu</span>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fa fa-trash mr-2" /> Delete all videos
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fa fa-cloud-download-alt mr-2" /> Export video
              </a>
              <div className="dropdown-divider" />
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="fullscreen"
              href="#"
              role="button"
            >
              <i className="fas fa-expand-arrows-alt fa-lg" />
            </a>
          </li>
        </ul>
      </nav>
      {/* /.navbar */}
    </div>
  );
}

export default MainHeader;
