import React from "react";

import ContentHeader from "./ContentHeader";
import UploadVideo from "../pages/UploadVideo";

function Home() {
  return (
    <div>
      <div className="content-wrapper">
        <ContentHeader />
        <section className="content">
          <div className="container-fluid">
            <UploadVideo />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
